const server = 'https://sigur-api.space-smart.ru';

export default {
    server: server,
    apiHost: `${server}/api`,
    condoUserId: 'dd15eba4-73fa-4bac-8284-49ff7ec717a9',
    userToken: 'xfB-cf44MdL09t0UpbxSB4vD2l26tUtY6C14Z_BjKv8',
    condoOrganizationId: '9c2f63c8-9288-4942-b24f-e11da26f6bec',
    currentOrganizationId: 'ef47f23a-d99d-4ba6-a4dd-07e0660a8f15',
    isProduction: true
}
